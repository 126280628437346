import {bool, node, string} from 'prop-types'
import classNames from 'classnames'
import React from 'react'

import useStyles from './styles'

const Section = ({
  children,
  id,
  hasBorderBottom,
  hasGreyBackground,
  hasPaddingTop,
  hasPaddingBottom,
  hasPaddingSide,
  hasSmallPadding,
  hasSmallWidth,
  hasVerySmallPadding,
  hasRichTextPadding,
  styles,
}) => {
  const classes = useStyles()

  return (
    <section
      id={id}
      style={styles}
      className={classNames('sideWrapper', classes.mainSection, {
        [classes.greyBackground]: hasGreyBackground,
        [classes.noTopPadding]: !hasPaddingTop,
        [classes.noBottomPadding]: !hasPaddingBottom,
        [classes.noSidePadding]: !hasPaddingSide,
        [classes.noSidePadding]: !hasPaddingSide,
        [classes.borderBottom]: hasBorderBottom,
        [classes.smallWidth]: hasSmallWidth,
      })}
    >
      <div
        className={classNames({
          [classes.mediumPadding]: !hasSmallPadding && !hasVerySmallPadding,
          [classes.smallPadding]: hasSmallPadding && !hasVerySmallPadding,
          [classes.verySmallPadding]: hasVerySmallPadding,
          [classes.richTextPadding]: hasRichTextPadding,
        })}
      >
        {children}
      </div>
    </section>
  )
}

Section.propTypes = {
  children: node,
  hasBorderBottom: bool,
  hasGreyBackground: bool,
  hasPaddingBottom: bool,
  hasPaddingSide: bool,
  hasPaddingTop: bool,
  hasSmallPadding: bool,
  hasVerySmallPadding: bool,
  id: string,
}

Section.defaultProps = {
  children: '',
  hasBorderBottom: false,
  hasGreyBackground: false,
  hasPaddingBottom: true,
  hasPaddingSide: true,
  hasPaddingTop: true,
  hasSmallPadding: false,
  hasVerySmallPadding: false,
  id: '',
}

export default Section
